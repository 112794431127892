<template>
  <div
    class="py-4 px-4"
    style="background-color: white;"
  >
    <h2 
      class="py-2 px-2 background-element-primary"
      style="border-radius: 5px; color: white;"
    >
      Inventarios
    </h2>
    <v-row
      class="py-4"
    >
      <v-col
        v-for="tableData in tablesData"
        :key="tableData.id"
        cols="12"
        md="6"
      >
        <dashboard-table
          :tableData="tableData"
        ></dashboard-table>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import { ApiAuth } from  '@/api_utils/axios-base'

export default {
  metaInfo: {
    title: 'Inventarios - Dashboard'
  },
  data() {
    return {
      tablesData: [
        {
          id: 1,
          tableName: "Inventario por Categoría",
          headers: [
            { text: 'Nombredos', value: 'pruebaNombre', sortable: false },
            { text: 'Ventas promedio/semana', value: 'muchas', sortable: false },
            { text: 'Inventario Actual dos', value: '55', sortable: false },
            { text: 'Semanas Inventario dos', value: 'semanas', sortable: false }
          ],
          items: []
        },
        {
          id: 2,
          tableName: "Inventario por Almacén",
          headers: [
            {
              text: 'Almacén',
              value: 'almacen.nombre_almacen',
              sortable: false, 
            },
            {
              text: 'Producto',
              value: 'producto.descripcion_corta',
              sortable: false, 
            },
            {
              text: 'Inventario Actual',
              value: 'cantidad_disponible',
              sortable: false,
              filterable: false
            },
          ],
          items: []
        }
      ]
    }
  },
  components: {
    dashboardTable: () => import('@/components/utils/Tables/DashboardTable'),
  },
  created() {
    this.invPorAlmacen()
  },
  methods: {
    async invPorAlmacen() {
      try {
        let response = await ApiAuth.get('/reportes/api/inventario-almacen/')
        let inventarios = await response.data

        this.tablesData[1].items = inventarios
      } catch(error) {
        console.error("error getting invent", error)
      }
      await ApiAuth.get(`/reportes/api/inventario-almacen/`)
        .then(resp => {
          this.tablesData[1].items = resp.data
        })
    }
  }
}
</script>

<style>
</style>
